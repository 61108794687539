<template>
    <div class="role_main">
        <!-- 标题 -->
        <div class="title_page">
            <div class="sig"></div>
            <div class="page_name">权限组列表</div>
        </div>
        <!-- 表格内容 -->
        <div class="avatar_table">
            <el-card>
                <!-- 添加 删除按钮部分 -->
                <div class="add_del_btn">
                    <el-button type="primary" class="addBtn" @click="addition">添加
                    </el-button>
                    <el-button class="delBtn" @click="deltion">删除</el-button>
                </div>
                <!-- 内容表格部分 -->
                <div class="table_init">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                        @selection-change="handleSelectionChange"
                        :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="uid" label="ID">
                        </el-table-column>
                        <el-table-column prop="user_name" label="权限名称">
                        </el-table-column>
                        <el-table-column prop="type" label="权限类型">
                        </el-table-column>
                        <el-table-column prop="auth_status" label="状态">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span style="margin-right:16px" @click="handle_Edit(scope.row.uid)">编辑</span>
                                <span @click="handle_Delet(scope.row.uid)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
            </el-card>
        </div>
        <!-- 弹框部分 -->
        <!-- 添加编辑弹框 -->
        <el-dialog :visible.sync="add_edit_dialog" width="828px" :before-close="handleClose" @close="handle_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{title_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="80px">
                <el-form-item label="权限名称">
                    <el-input v-model="auth_name" placeholder="请输入权限名称" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="权限类型">
                    <el-select v-model="authType_id" placeholder="请选择权限类型" clearable size="mini">
                        <el-option v-for="item in authType" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="权限列表">
                    <div class="routeList-box">
                        <el-tree class="el-tree" :data="routeList" :props="defaultProps" show-checkbox
                            :render-content="renderContent" node-key="id" ref="tree" highlight-current
                            @node-expand="handleExpand" @check-change="handleCheckChange"
                            :default-checked-keys="default_arr" :check-strictly="checkStrictly">
                        </el-tree>
                    </div>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio v-model="radio" label="1">正常</el-radio>
                    <el-radio v-model="radio" label="2">禁用</el-radio>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="handle_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="handle_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getGroupApi, showGroupApi, addGroupApi, editGroupApi, delGroupApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 弹框显示隐藏
            add_edit_dialog: false,   //添加编辑弹框
            del_dialog: false,    //删除弹框
            // 每条数据id
            id: '',
            //  表格数据
            tableData: [],
            multipleSelection: [],
            // 分页
            page: 1,
            count: 0,
            max: 10,
            // 弹框标题  是添加还是编辑
            title_name: '',
            btn_name: '',
            // *********表单验证内容*********
            auth_name: '',   //权限名称
            authType: [],   //权限类型
            authType_id: '',
            routeList: [],  //权限列表
            defaultProps: {
                children: "children",
                label: "auth_name"
            },
            // 树形节点选中数组
            auth_ids: [],
            // 树形控件默认选中数组   去除点父级id的数组
            default_arr: [],
            // 树形控件默认选中数组   没有去除点父级id的数组
            def_arr: [],
            // 所有父级id数组
            getParId: [],
            checkStrictly: false,
            radio: '1',   //状态
            radio_st: 1,
            // 删除数据的数组
            del_arr: [],
            // 删除提示语
            del_tip: '',
        }
    },
    // 树形控件
    mounted () {
        this.changeCss();
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            getGroupApi({
                page: this.page
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
        // 弹窗关闭
        handleClose () {
            this.add_edit_dialog = false;
            this.del_dialog = false;
        },
        // 点击添加按钮
        addition () {
            this.add_edit_dialog = true;
            this.title_name = '添加';
            this.btn_name = '确认添加';
            showGroupApi({
                type: 1
            }).then(res => {
                // console.log(res);
                if (res.status.code == 200) {
                    this.authType = res.type;   //权限类型
                    this.routeList = res.auth;   //权限列表
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 点击编辑按钮
        handle_Edit (id) {
            this.add_edit_dialog = true;
            this.id = id;
            this.title_name = '编辑';
            this.btn_name = '确认修改';
            showGroupApi({
                type: 2,
                id: id
            }).then(res => {
                // console.log(res);
                if (res.status.code == 200) {
                    this.authType = res.type;   //权限类型
                    this.routeList = res.auth;   //权限列表
                    // console.log(this.routeList)
                    let fa_arr = [];
                    let son_arr = [];
                    this.routeList.map(item => {
                        // console.log(item)
                        if (item.children.length == 0) {
                            fa_arr.push(item.id);
                            // console.log(fa_arr)
                        } else {
                            fa_arr.push(item.id);
                            // console.log(fa_arr)
                            item.children.map(child_arr => {
                                son_arr.push(child_arr.id);
                            })
                        }
                    });
                    //存放所有父级id的合集
                    this.getParId = fa_arr.concat(son_arr);
                    // console.log(this.getParId)
                    this.auth_name = res.data.user_name;   //权限名称
                    this.authType_id = res.data.type;  //权限类型id
                    this.def_arr = res.data.user_auth.split(',').map((value) => { return Number(value); });//权限默认选中
                    // console.log(a)
                    for (let i = 0, len = this.def_arr.length; i < len; i++) {
                        if (this.getParId.indexOf(this.def_arr[i]) < 0) {
                            this.default_arr.push(this.def_arr[i]);
                        }
                    };
                    // console.log(this.default_arr)
                    this.radio_st = res.data.auth_status;  //状态
                    if (this.radio_st == 1) {
                        this.radio = '1'
                    } else if (this.radio_st == 2) {
                        this.radio = '2'
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加 编辑，树形控件
        handleExpand () {//节点被展开时触发的事件
            //因为该函数执行在renderContent函数之前，所以得加定时
            setTimeout(() => {
                this.changeCss();
            }, 10);
        },
        renderContent (h, { node, data, store }) {//树节点的内容区的渲染 Function
            let classname = "";
            // 由于项目中有二级菜单也有三级级菜单，就要在此做出判断
            if (node.level === 3) {
                classname = "foo";
            }
            if (node.level === 2 && node.childNodes.length === 0) {
                classname = "foo";
            }
            return h(
                "p",
                {
                    class: classname
                },
                node.label
            );
        },
        changeCss () {
            var levelName = document.getElementsByClassName("foo"); // levelname是上面的最底层节点的名字
            for (var i = 0; i < levelName.length; i++) {
                // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
                levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
                levelName[i].parentNode.style.styleFloat = "left";
                levelName[i].parentNode.onmouseover = function () {
                    this.style.backgroundColor = "#fff";
                };
            }
        },
        // 节点选中状态发生变化时的回调
        handleCheckChange () {
            let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
            this.auth_ids = res.join(',');
        },
        // 添加  编辑 点击提交按钮
        handle_confirm () {
            if (this.radio == '1') {
                this.radio_st = 1;
            } else if (this.radio == '2') {
                this.radio_st = 2;
            };
            if (this.title_name == '添加') {
                // console.log(this.auth_ids);
                // console.log(111111111)
                addGroupApi({
                    user_name: this.auth_name,  //权限名称
                    user_auth: this.auth_ids,    //权限列表
                    type: this.authType_id,  //权限类型id
                    auth_status: this.radio_st,   //邮箱
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.title_name == '编辑') {
                if (this.auth_ids.length == 0) {
                    this.auth_ids = this.def_arr.join(',')
                };
                // console.log(this.auth_ids);
                editGroupApi({
                    id: this.id,
                    user_name: this.auth_name,  //权限名称
                    user_auth: this.auth_ids,    //权限列表
                    type: this.authType_id,  //权限类型id
                    auth_status: this.radio_st,   //邮箱
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加  编辑  点击重置按钮  弹窗点击遮罩关闭
        handle_reset () {
            this.auth_name = '';  //权限名称
            this.authType_id = '';    // 权限类型
            this.$refs.tree.setCheckedKeys([]);   // 权限列表
            this.radio = '1';   //状态
            this.getParId = [];
            this.default_arr = [];
        },
        // 点击删除按钮  单个删除按钮
        handle_Delet (id) {
            this.id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除
        deltion () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.multipleSelection.length; i++) {
                let del_id = this.multipleSelection[i].uid;
                this.del_arr.push(del_id);
            };
        },
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除点击确定按钮
        handle_del () {
            delGroupApi({
                id: this.del_arr,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('删除成功');
                    this.del_dialog = false;
                    this.initData();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.role_main {
    width: 100%;
    height: 100%;
    // 页面标题部分
    .title_page {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sig {
            width: 18px;
            height: 4px;
            margin-right: 8px;
            background: #2479dd;
        }
        .page_name {
            font-size: 18px;
            font-weight: 600;
        }
    }
    // 表格显示内容
    .avatar_table {
        margin-bottom: 20px;
        // 添加  删除按钮部分
        .add_del_btn {
            text-align: right;
            .el-button {
                width: 80px;
                height: 30px;
                line-height: 4px;
            }
            .addBtn {
                margin-right: 8px;
                font-size: 14px;
                font-weight: 400;
            }
        }
        // 表格内容
        .table_init {
            margin-top: 20px;
            text-align: center;
            // 操作按钮
            span {
                border: none;
                width: 24px;
                height: 18px;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            display: flex;
            align-items: center;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            .sd {
                width: 18px;
                height: 4px;
                margin-right: 8px;
                background: #2479dd;
            }
            .ax {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
        }
        // 添加  编辑  弹框树形控件
        .routeList-box {
            margin-bottom: 20px;
            .el-collapse {
                border: none;
            }
            .el-tree {
                margin: 12px 0;
            }
            ::v-deep.el-tree-node__content {
                padding-left: 0px;
            }
            ::v-deep.el-collapse-item__header {
                height: 40px;
                background-color: #f4f4f4;
                padding: 0 10px;
            }
            ::v-deep.el-icon-arrow-right:before {
                color: #409eff;
            }
        }
        ::v-deep .el-tree-node__content::before {
            content: "";
            padding-left: 10px;
        }
        ::v-deep.el-checkbox__input {
            margin-right: 6px;
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>